import React from 'react';
import { unified } from 'unified';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { Text, List, Divider, ListItem } from '@audi/audi-ui-react';
import * as prod from 'react/jsx-runtime';

import type { Options } from 'rehype-react';
import type { ReactElement, JSXElementConstructor, ReactNode } from 'react';

const defaultOptions = { Fragment: prod.Fragment, jsx: prod.jsx, jsxs: prod.jsxs };
const rehypeReactOptions = {
  ...defaultOptions,
  components: {
    h1(props: React.PropsWithChildren) {
      return (
        <Text as="h1" variant="order1" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    h2(props: React.PropsWithChildren) {
      return (
        <Text as="h2" variant="order2" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    h3(props: React.PropsWithChildren) {
      return (
        <Text as="h3" variant="order3" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    h4(props: React.PropsWithChildren) {
      return (
        <Text as="h4" variant="order4" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    h5(props: React.PropsWithChildren) {
      return (
        <Text as="h5" variant="order4" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    h6(props: React.PropsWithChildren) {
      return (
        <Text as="h6" variant="order4" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    p(props: React.PropsWithChildren) {
      return (
        <Text as="p" variant="copy1" spaceStackEnd="m">
          {processChildren(props.children)}
        </Text>
      );
    },
    div(props: React.PropsWithChildren) {
      return <div>{processChildren(props.children)}</div>;
    },
    span(props: React.PropsWithChildren) {
      return (
        <Text as="span" variant="copy1">
          {processChildren(props.children)}
        </Text>
      );
    },
    ul(props: React.PropsWithChildren) {
      return (
        <List variant="bullet" spaceStackEnd="m">
          {processChildren(props.children)}
        </List>
      );
    },
    ol(props: React.PropsWithChildren) {
      return (
        <List variant="decimal" spaceStackEnd="m">
          {processChildren(props.children)}
        </List>
      );
    },
    li(props: React.PropsWithChildren) {
      return <ListItem>{processChildren(props.children)}</ListItem>;
    },
    hr() {
      return <Divider spaceStackEnd="xxl" spaceStackStart="xxl" />;
    },
    blockquote({ children, ...props }: React.PropsWithChildren) {
      return (
        <blockquote {...props}>
          <Text as="p" variant="copy1">
            {processChildren(children)}
          </Text>
        </blockquote>
      );
    },
    pre(props: React.PropsWithChildren) {
      return (
        <Text as="span" spaceStackEnd="m">
          <pre>{processChildren(props.children)}</pre>
        </Text>
      );
    },
    a({ children, ...props }: React.PropsWithChildren) {
      return <a {...props}>{processChildren(children)}</a>;
    },
    strong({ children }: React.PropsWithChildren) {
      return (
        <Text as="strong" variant="copy1" weight="bold">
          {processChildren(children)}
        </Text>
      );
    },
  },
};
const processChildren = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: string | ReactNode | ReactElement<any, string | JSXElementConstructor<any>>[],
) => {
  if (typeof children === 'string') {
    return processChild(children);
  }
  if (Array.isArray(children)) {
    return children.map((child) => processChild(child as unknown as string));
  }
  return children;
};
const processChild = (value: string) => {
  if (typeof value === 'string' && value.match(/({ft_[a-z0-9-_]*})/gi)) {
    return renderTextWithFootnotesReferencesV2(value);
  }
  return value;
};

export const convertHtmlToReact = (html: string) => {
  const file = unified()
    .use(rehypeParse, {
      fragment: true,
    })
    .use(rehypeReact, rehypeReactOptions as unknown as Options)
    .processSync(html);

  return file.result;
};
