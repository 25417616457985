import styled from 'styled-components';

import {
  COLOR_BASE_BRAND_BLACK,
  COLOR_BASE_BRAND_WHITE,
  SPACING_XL_XS,
  SPACING_XXXL_M,
  SPACING_XXXL_L,
  SPACING_XXL_L,
  SPACING_XXXL_XL,
  BREAKPOINT_M,
  BREAKPOINT_L,
  BREAKPOINT_XL,
} from '@audi/audi-ui-design-tokens';
import type { ContainerDivProps, TextWrapperProps } from '../../@types/interfaces';

export const ContainerDiv = styled.div<ContainerDivProps>`
  background-color: ${(props) =>
    props.theme === 'light' ? COLOR_BASE_BRAND_WHITE : COLOR_BASE_BRAND_BLACK};
  padding: ${(props) =>
    props.bottomMargin
      ? `${SPACING_XXL_L}px ${SPACING_XL_XS}px`
      : `${SPACING_XXL_L}px ${SPACING_XL_XS}px 0`};

  sup a {
    text-decoration: none;
  }

  ul,
  ol {
    display: inline-block;
    li {
      ::before {
        left: unset;
      }
      sup a:-webkit-any-link {
        color: unset !important;
      }
    }
  }

  @media (min-width: ${BREAKPOINT_M}px) {
    padding: ${(props) =>
      props.bottomMargin
        ? `${SPACING_XXXL_M}px ${SPACING_XXL_L}px`
        : `${SPACING_XXXL_M}px ${SPACING_XXL_L}px 0`};
  }

  @media (min-width: ${BREAKPOINT_L}px) {
    padding: ${(props) =>
      props.bottomMargin ? `${SPACING_XXXL_L}px` : `${SPACING_XXXL_L}px ${SPACING_XXXL_L}px 0`};
  }

  @media (min-width: ${BREAKPOINT_XL}px) {
    padding: ${(props) =>
      props.bottomMargin ? `${SPACING_XXXL_XL}px 96px` : `${SPACING_XXXL_XL}px 96px 0`};
  }
`;

export const ButtonContainerDiv = styled.div`
  margin-block-start: ${SPACING_XXL_L}px;
  margin-bottom: 0;
`;

export const TextWrapperDiv = styled.div<TextWrapperProps>`
  text-align: ${(props) => props.align};

  pre {
    white-space: pre-wrap;
  }
`;
