import React from 'react';
import {
  audiDarkTheme,
  audiLightTheme,
  Layout,
  LayoutItem,
  Button,
  ThemeProvider,
  GlobalStyles,
} from '@audi/audi-ui-react';

import {
  FootnoteContextProvider,
  // renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';

import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import { UeElement } from '@oneaudi/falcon-tools';
import type { EditorialTextProps } from '../../@types/types';

import { processBodyText } from './processBodyText';

import { ContainerDiv, ButtonContainerDiv, TextWrapperDiv } from './styledComponents';
import { ConsumptionAndEmission } from './ConsumptionAndEmission';

export const EditorialText = ({
  content,
  footnoteReferenceService,
  vueFormatterService,
}: EditorialTextProps) => {
  const {
    bodyText,
    theme,
    justification,
    ctaButtonLabel,
    ctaButtonUrl,
    ctaButtonOpenInNewTab,
    ariaLabel,
    consuptionAndEmission,
    disclaimer,
    bottomMargin,
  } = content;

  const themeActive = theme === 'dark' ? audiDarkTheme : audiLightTheme;

  let testingThemeActive = 'unknown';
  if (themeActive.name.toLowerCase() === 'audi dark theme') {
    testingThemeActive = 'dark';
  }
  if (themeActive.name.toLowerCase() === 'audi light theme') {
    testingThemeActive = 'light';
  }

  // const processedBodyText = processBodyText(bodyText);

  const setNewWindow = (input: string | boolean) => {
    if (typeof input !== 'boolean') {
      return input === 'newTab';
    }
    return input;
  };

  const processedCopy = processBodyText(bodyText);

  return (
    <div data-theme-name={testingThemeActive}>
      <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService || {}}>
        <GlobalStyles />
        <ThemeProvider theme={themeActive}>
          {bodyText && (
            <ContainerDiv theme={theme} bottomMargin={bottomMargin}>
              <Layout data-test-id="title" direction="column">
                <LayoutItem align="auto" basis="100%">
                  <TextWrapperDiv data-testid="textWrapper" align={justification} theme={theme}>
                    {processedCopy}
                  </TextWrapperDiv>
                </LayoutItem>

                {ctaButtonUrl?.length > 0 && ctaButtonLabel?.length > 0 && (
                  <LayoutItem align={justification} basis="auto">
                    <ButtonContainerDiv>
                      <Button
                        variant="text"
                        aria-label={ariaLabel || ctaButtonLabel}
                        newWindow={setNewWindow(ctaButtonOpenInNewTab)}
                        href={ctaButtonUrl}
                        onClick={() => {}}
                      >
                        <UeElement type="text" property="ctaButtonLabel" label="CTA Label">
                          {ctaButtonLabel}
                        </UeElement>
                      </Button>
                    </ButtonContainerDiv>
                  </LayoutItem>
                )}
                {consuptionAndEmission?.length > 0 || disclaimer?.length > 0 ? (
                  <ConsumptionAndEmission
                    consuptionAndEmission={consuptionAndEmission}
                    disclaimer={disclaimer}
                    theme={themeActive.name}
                    vueFormatterService={
                      vueFormatterService as VueFormatterFeatureServiceInterfaceV1
                    }
                  />
                ) : null}
              </Layout>
            </ContainerDiv>
          )}
        </ThemeProvider>
      </FootnoteContextProvider>
    </div>
  );
};
